import { abortNavigation, defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { showAlert } from '@/composables/useDialog';
import { ERROR_CODE } from '@/constants/error.const';
import { PRODUCT_HOME_PAGE_NAME } from '@/constants/url.const';
import useProductStore from '@/stores/product.store';
import { isDirectAccess, redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const productStore = useProductStore();

    const { isReleased, isVerifyPass, isPageBuild } = storeToRefs(productStore);

    if (!isReleased.value) {
      if (!(isVerifyPass.value && isPageBuild.value)) {
        const nuxtApp = useNuxtApp();
        const { t } = nuxtApp.$i18n as { t: any; locale: any };
        const { name: toName } = to;
        const { name: fromName } = from;
        if (isDirectAccess(toName, fromName)) {
          return await redirectTo(PRODUCT_HOME_PAGE_NAME, {
            toType: 'name',
            query: { error: ERROR_CODE.DISCOUNT_PRE_RELEASED_ACCESS }
          });
        }

        await showAlert({
          content: t('studio.discount.verify_not_passed.cannot_access_msg')
        });
        return abortNavigation();
      }
    }
  }
);
